import React, { createContext } from 'react';
import { AuthorizationProvider } from 'dx-sdk/build/authorization';
import { getAuth0Domain, getRetailClientId } from './utils/config';
import DXAccountContextProvider from './contexts/DXAccountContext';

export const DXLoginContext = createContext({
  state: {
    clientId: null,
  },
});

const DXAuthorizationWrapper = ({ routes, children, dealer }) => {
  const brandRegionText = (brand, region) => {
    if (brand && brand != '' && brand != 'CLA' && brand != 'cla') {
      return brand;
    } else if (brand && brand !== '' && region) {
      return brand + region;
    } else {
      return null;
    }
  };

  const staticClientID = getRetailClientId(
    brandRegionText(dealer?.brandAcronym, dealer?.region)
  );

  const currentContext = {
    state: {
      clientId: staticClientID,
    },
  };

  return (
    <AuthorizationProvider
      domain={getAuth0Domain()}
      clientId={staticClientID || null}
      cacheLocation="localstorage"
      authorizationParams={{
        audience: 'myhome-api',
        scope:
          'Retail:Web:Read Retail:Web:Write Global:Read:User Global:Write:User profile email offline_access openid',
        redirect_uri:
          typeof window !== 'undefined' ? window.location.origin : '',
      }}
    >
      <DXLoginContext.Provider value={currentContext}>
        <DXAccountContextProvider dealer={dealer}>
          {children}
        </DXAccountContextProvider>
      </DXLoginContext.Provider>
    </AuthorizationProvider>
  );
};

export default React.memo(DXAuthorizationWrapper);

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { getGraphUserURI } from './config';
import fetch from 'isomorphic-fetch';
import { ENVIRONMENT } from './config';

export const isMobile = (size = 1051) => {
  if (typeof window !== 'undefined') {
    return window.innerWidth < size;
  }
};

export const hasQueryParams = () => {
  if (typeof window !== 'undefined') {
    const url = window.location.href;
    return url.includes('?');
  }
};

export const hasLotNumberParam = () => {
  if (typeof window !== 'undefined') {
    const url = window.location.href;
    return url.includes('lotNumber');
  }
};

const sessionStorage = {};

export const storeSession = (key, value) => {
  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  if (typeof value !== 'string') {
    value = JSON.stringify(value, getCircularReplacer());
  }

  if (typeof window === 'undefined') {
    sessionStorage[key] = value;
    return;
  }

  return window?.sessionStorage?.setItem(key, value);
};

export const getCookie = (name, serverSide = false) => {
  if (typeof window === 'undefined' || !window.document) {
    return null;
  }
  if (
    document.cookie.split(';').some((item) => item.trim().startsWith('vt='))
  ) {
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith(name))
      .split('=')[1];
    return cookieValue;
  }
  return null;
};

export const parseCookie = (request) => {
  const {
    headers: { cookie },
  } = request;

  return cookie.split(';').reduce((res, item) => {
    const data = item.trim().split('=');
    return { ...res, [data[0]]: data[1] };
  }, {});
};

export const setCookie = (name, value) => {
  if (!window || !window.document) {
    return null;
  }

  document.cookie = `${name}=${value}`;
};

export const findTempoHomes = (homes) => {
  // to enable tempo homes, remove the line below
  return null;

  if (!homes) {
    return null;
  }

  let tempoHomes = [];

  let letItBeList = [
    '25TEM28563AH',
    '27TEM28563AM',
    '44TEM28563AH',
    '51TEM28563AH',
    '51TMP28563AH',
    '52TEM28563AH',
    '55TMP28563AH',
    '56TEM28563AH',
    '57TEM28563AH',
    '57TMC28563AH',
    '58TEM28563AM',
    '71TMP28563AH',
    '72TEM28563AH',
    '81TEM28563AH',
    '81TMP28563AH',
  ];

  let solsburyHillList = [
    '27TEM16763AH',
    '27TMC16763AH',
    '51TEM16763AH',
    '51TMP16763AH',
    '52TEM16763AH',
    '54TEM16763AH',
    '57TEM16763AH',
    '57TMC16763RH',
    '71TEM16763AH',
    '71TMP16763AH',
    '72TEM16763AH',
    '81TEM16763AH',
    '81TMP16763AH',
  ];

  let lovelyDayList = [
    '25TEM28604AH',
    '27TEM28604AM',
    '44TEM28604AH',
    '51TEM28604AH',
    '51TMP28604AH',
    '52TEM28604AH',
    '55TMP28604AH',
    '56TEM28604AH',
    '57TEM28604AH',
    '57TMC28604AH',
    '58TEM28604AM',
    '71TEM28604AH',
    '71TMP28604AH',
    '72TEM28604AH',
  ];

  let walkTheLineList = [
    '27TEM16723BH',
    '44TEM16723BH',
    '52TEM16723AH',
    '55TMP16723BH',
    '57TEM16723BH',
    '57TEM16723BH',
  ];

  const letItBe = homes.find((home) => letItBeList.includes(home.modelNumber));
  const solsburyHill = homes.find((home) =>
    solsburyHillList.includes(home.modelNumber)
  );
  const lovelyDay = homes.find((home) =>
    lovelyDayList.includes(home.modelNumber)
  );
  const walkTheLine = homes.find((home) =>
    walkTheLineList.includes(home.modelNumber)
  );

  if (solsburyHill) {
    tempoHomes.push(solsburyHill);
  }

  if (letItBe) {
    tempoHomes.push(letItBe);
  }

  if (lovelyDay) {
    tempoHomes.push(lovelyDay);
  }

  if (walkTheLine) {
    tempoHomes.push(walkTheLine);
  }

  if (tempoHomes.length < 3) {
    return null;
  }

  return tempoHomes.slice(0, 3);
};

export const findFeaturedHomes = (homes) => {
  let expensiveHome;
  let affordableHome;
  let oldHome;
  let finalHomeArr;
  let filteredHomeArr;
  let inventoryCheck;
  let oldHomesArray;
  let alternateHome;
  let featuredHomesArray;
  let noDuplicates;
  let availableHomes;

  if (!homes || (homes && homes.length < 2)) {
    return null;
  }

  if (homes && homes.length <= 3) {
    return homes;
  }

  filteredHomeArr = homes.filter(
    (h) => h.serialNumber && ![-1, 5].includes(h.stickerTypeId)
  );

  availableHomes = homes.filter((h) => ![-1, 5].includes(h.stickerTypeId));

  inventoryCheck =
    filteredHomeArr && filteredHomeArr.length >= 3
      ? filteredHomeArr
      : availableHomes;

  if (inventoryCheck && inventoryCheck.length > 0) {
    expensiveHome = inventoryCheck.reduce((acc, loc) =>
      acc.priceSort > 0 && acc.priceSort > loc.priceSort ? acc : loc
    );

    affordableHome = inventoryCheck.reduce((acc, loc) =>
      acc.priceSort > 0 && acc.priceSort < loc.priceSort ? acc : loc
    );

    oldHome = inventoryCheck.reduce((acc, loc) =>
      acc.priceSort > 0 && acc.creationDate < loc.creationDate ? acc : loc
    );

    oldHomesArray = inventoryCheck.sort((a, b) => {
      return a.creationDate - b.creationDate;
    });

    alternateHome = oldHomesArray[1];

    featuredHomesArray = [expensiveHome, affordableHome, oldHome];

    noDuplicates = [...new Set(featuredHomesArray)];

    finalHomeArr =
      noDuplicates.length === 3
        ? noDuplicates
        : [...noDuplicates, alternateHome];

    return finalHomeArr;
  } else {
    return null;
  }
};

export const getFOBShortName = (brand) => {
  if (brand === 'FRE') {
    return 'Freedom';
  } else if (brand === 'OAK') {
    return 'Oakwood';
  } else if (brand === 'CSL') {
    return 'Crossland';
  } else if (brand === 'LUV') {
    return 'Luv';
  } else if (brand === 'INT') {
    return 'International';
  } else if (brand === 'TRU') {
    return 'TRU';
  } else if (brand === 'SCO') {
    return 'Scotbilt';
  } else {
    return 'Clayton';
  }
};

// https://github.com/coolaj86/knuth-shuffle
export const shuffle = (array) => {
  var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

// put managers at beginning and admins at end
export const sortSalesAgents = (array) => {
  const mappedArray = array.map((agent) => {
    switch (agent.salesAgentTitle.toLowerCase()) {
      case 'home center manager':
        agent.sortOrder = 1;
        break;
      case 'manager in training':
        agent.sortOrder = 2;
        break;
      case 'sales manager':
        agent.sortOrder = 3;
        break;
      case 'sales professional':
        agent.sortOrder = 4;
        break;
      case 'project manager':
        agent.sortOrder = 5;
        break;
      case 'admin':
        agent.sortOrder = 6;
        break;
      default:
        agent.sortOrder = 100;
    }

    return agent;
  });

  const shuffledArray = shuffle(mappedArray);

  const orderedArray = shuffledArray.sort((a, b) => {
    return a.sortOrder - b.sortOrder;
  });

  return orderedArray;
};

const serverState = typeof window !== 'undefined' && window.__APOLLO_STATE__;

/*
Apollo Client is needed every time
a user needs access to the GraphQL Endpoints */
export const apolloClient = new ApolloClient({
  link: createHttpLink({
    uri: getGraphUserURI(),
    fetch: fetch,
  }),
  cache: new InMemoryCache().restore(serverState),
  ssrForceFetchDelay: 100,
  defaultOptions: {
    query: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export const shouldFeatureWelcomeHomeGallery = (dealer) => {
  const galleryImages = dealer?.welcomeHomeImages?.filter(
    (image) => image.isShownInGallery
  );
  return galleryImages?.length >= 12;
};

export const fireMNTNConversionPixel = () => {
  var x = null,
    p,
    q,
    m,
    o = '34838',
    l = 'ORDER ID',
    i = 'TOTAL ORDER AMOUNT',
    c = '',
    k = '',
    g = '',
    j = '',
    u = '',
    shadditional = '';
  try {
    p =
      top.document.referer !== ''
        ? encodeURIComponent(top.document.referrer.substring(0, 512))
        : '';
  } catch (n) {
    p =
      document.referrer !== null
        ? document.referrer.toString().substring(0, 512)
        : '';
  }
  try {
    q =
      window &&
      window.top &&
      document.location &&
      window.top.location === document.location
        ? document.location
        : window &&
          window.top &&
          window.top.location &&
          '' !== window.top.location
        ? window.top.location
        : document.location;
  } catch (b) {
    q = document.location;
  }
  try {
    m =
      parent.location.href !== ''
        ? encodeURIComponent(parent.location.href.toString().substring(0, 512))
        : '';
  } catch (z) {
    try {
      m = q !== null ? encodeURIComponent(q.toString().substring(0, 512)) : '';
    } catch (h) {
      m = '';
    }
  }
  var A,
    y = document.createElement('script'),
    w = null,
    v = document.getElementsByTagName('script'),
    t = Number(v.length) - 1,
    r = document.getElementsByTagName('script')[t];
  if (typeof A === 'undefined') {
    A = Math.floor(Math.random() * 100000000000000000);
  }
  w =
    'dx.mountain.com/spx?conv=1&shaid=' +
    o +
    '&tdr=' +
    p +
    '&plh=' +
    m +
    '&cb=' +
    A +
    '&shoid=' +
    l +
    '&shoamt=' +
    i +
    '&shocur=' +
    c +
    '&shopid=' +
    k +
    '&shoq=' +
    g +
    '&shoup=' +
    j +
    '&shpil=' +
    u +
    shadditional;
  y.type = 'text/javascript';
  y.src =
    ('https:' === document.location.protocol ? 'https://' : 'http://') + w;
  r.parentNode.insertBefore(y, r);
};

export const findMetaDescription = (websiteMetaDescriptions, pagePath) => {
  if (!websiteMetaDescriptions || !pagePath) {
    return null;
  }
  const matchingMetaEntry = websiteMetaDescriptions?.find(
    (item) => item.websitePagePath === pagePath
  );
  return matchingMetaEntry;
};
